<!-- content section -->
<div class="content-container" [@listAnimation]="filteredInventory.length">
    <ng-container *ngIf="filteredInventory.length < 1">No results found.</ng-container>
    <ng-container *ngFor="let inventory of filteredInventory">
        <div class="inventory-card item-card-ref" (click)="goToInventoryDetails(inventory)">
            <!-- <div class="inventory-title"> {{ inventory.nickname }} </div> -->
            <div class="image-container">
                <img *ngIf="inventory.image === ''" [src]="'/icon/placeholder.svg' | assetUrl" alt="">
                <img *ngIf="inventory.image !== ''" [src]="inventory.image" alt="">
            </div>
            <div class="inventory-details">
                <div class="catalog-type" [style.color]="inventory.catalogInfo.type == 'Drone' ? '#536DFE' : '#B388FF'"> {{ inventory.catalogInfo.type }}</div>
                <div>
                    {{ inventory.nickname == '' || inventory.nickname == null ? '-' : inventory.nickname }}
                </div>
                <div class="tags-container">
                    <div class="custom-tag">
                        {{ inventory.catalogInfo.subtype }}
                    </div>
                    <div>
                        {{ inventory.flightHour }} Hrs
                    </div>
                </div>
                <div class="details-container">
                    <div class="d-flex w-100">
                        <div class="item-container">
                            <div class="detail-label">BRAND</div>
                            <div class="title">{{ inventory.catalogInfo.brand }}</div>
                        </div>
                        <div class="item-container">
                            <div class="detail-label">MODEL</div>
                            <div class="title">{{ inventory.catalogInfo.model }}</div>
                        </div>
                    </div>
                    <div class="item-container">
                        <div class="detail-label">SERIAL NUMBER</div>
                        <div class="title">{{ inventory.serialNumber }}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- sidepanel view inventory details -->
<app-sidepanel *ngIf="isOpenPanel" class="view-details"
    (closePanel)="closeHandler()" [headerTitle]="'Drone Details'" [footerBtnText]="'Add to My Inventory'" [hideFooter]="true">
    <app-inventory-details [selectedCatalog]="selectedCatalog"></app-inventory-details>
</app-sidepanel>