import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-model-popup',
  templateUrl: './model-popup.component.html',
  styleUrls: ['./model-popup.component.scss']
})
export class ModelPopupComponent implements OnInit {
  @Output() closeEvent = new EventEmitter()
  constructor(private router: Router) {}

  models: Array<string> = ['drone', 'payload', 'asset', 'equipment']
  ngOnInit(): void {
  }

  closeModelPopup() {
    const modelPopupContainerEl = document.querySelector('.model-popup-container')
    modelPopupContainerEl?.classList.add('fade-out')
    setTimeout(() => {
      this.closeEvent.emit(true)
    }, 300)
  }

  goToForm(modelType: string) {
    this.router.navigate([`catalogue/form`, modelType])
  }
}
