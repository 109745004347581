import { Component, EventEmitter, Input, Output } from '@angular/core';

interface CatalogDetails {
  image: string,
  type: string,
  brand: string,
  model: string,
  subtype: string,
}

@Component({
  selector: 'app-catalog-card',
  templateUrl: './catalog-card.component.html',
  styleUrls: ['./catalog-card.component.scss']
})
export class CatalogCardComponent {
  @Input() catalog: CatalogDetails = {
    image: '',
    type: '',
    subtype: '',
    brand: '',
    model: '',
  }
  @Input() disabled: boolean = false

  @Output() cardClicked = new EventEmitter<any>();

  clickHandler() {
    this.cardClicked.emit(true)
  }
}
