<div class="sidepanel-container slide-in">
    <div class="header">
        <div>{{ headerTitle }}</div>
        <div>
            <img [src]="'/icon/close-bold.svg' | assetUrl" alt="" (click)="closeHandler()">
        </div>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
    <div *ngIf="!hideFooter" class="footer">
        <app-global-btn *ngIf="hasCancel" class="custom-btn" [btnBorder]="'#2D343F'" [btnBg]="'#22263166'" [isIcon]="false" [btnText]="'Cancel'" (btnClicked)="closeHandler()"></app-global-btn>
        <app-global-btn class="custom-btn" [isIcon]="false" [btnText]="footerBtnText" (btnClicked)="btnHandler()"></app-global-btn>
    </div>
</div>
<div class="underlay-container" (click)="closeHandler()"></div>