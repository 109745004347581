<div class="search-container">
    <img [src]="'/icon/search-status.svg' | assetUrl" alt="">
    <input [(ngModel)]="searchInput" type="text" placeholder="Quick search  (⌘K)" (input)="keyupHandler($event)">
</div>
<div class="filter-container" (click)="!showFilterDrop ? toggleFilter() : ''">
    <div style="position: relative;">
        <img [src]="'/icon/filter.svg' | assetUrl" alt="">
        <div *ngIf="totalActiveFilter > 0" class="filter-badge">
            {{ totalActiveFilter }}
        </div>
    </div>
    <div *ngIf="showFilterDrop" class="filter-drop-container">
        <div class="filter-header">
            <div>Filter</div>
            <div>
                <img [src]="'/icon/close-bold.svg' | assetUrl" alt="" (click)="toggleFilter()">
            </div>
        </div>
        <div class="filter-content">
            <div class="filter-input-group">
                <div>Subtype</div>
                <ng-multiselect-dropdown
                    [placeholder]="'Select category'"
                    [settings]="dropdownSettings"
                    [data]="filterOptions.subtypes"
                    [(ngModel)]="selectedCategory"
                    >
                </ng-multiselect-dropdown>
            </div>
            <div class="filter-input-group">
                <div>Brand</div>
                <ng-multiselect-dropdown
                    [placeholder]="'Select brand'"
                    [settings]="dropdownSettings"
                    [data]="filterOptions.brands"
                    [(ngModel)]="selectedBrand"
                    >
                </ng-multiselect-dropdown>
            </div>
            <div class="filter-input-group">
                <div>Model</div>
                <ng-multiselect-dropdown
                    [placeholder]="'Select model'"
                    [settings]="dropdownSettings"
                    [data]="filterOptions.models"
                    [(ngModel)]="selectedModel"
                    >
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="filter-footer">
            <div (click)="clearFilter()">
                Clear Filter
            </div>
            <div>
                <app-global-btn [btnWidth]="'100px'" [isIcon]="false" [btnText]="'Filter'" (btnClicked)="executeFilter()"></app-global-btn>
            </div>
        </div>
    </div>
</div>