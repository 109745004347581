<div class="inventory-list-container">
    <!-- header section -->
    <div class="header">
        <div>
            <div *ngIf="renderedUI === 0" class="title">NEW ENTRY</div>
            <div *ngIf="renderedUI === 0" class="subtitle">Please select the models you want to add in your inventory.</div>
            <div *ngIf="renderedUI === 1" class="title">MY INVENTORY</div>
            <div *ngIf="renderedUI === 2" class="title">CATALOGUE</div>
        </div>
        <div>
            <img *ngIf="renderedUI !== 2" class="close-icon pointer" [src]="'/bulk/close.svg' | assetUrl" alt="" routerLink="/">
            <app-global-btn *ngIf="renderedUI === 2" [btnText]="'New'" (btnClicked)="addNewClicked()"></app-global-btn>
        </div>
    </div>
    <!-- toolbar section -->
    <div class="toolbar-container">
        <!-- lefthand side -->
        <div>
            <ng-container *ngFor="let type of allInventoryType">
                <div class="toolbar-item" [ngClass]="{ 'active' : selectedType.name === type.name }"
                    (click)="toolbarClicked(type)">{{ type.name }}
                    <!-- <div class="count" *ngIf="renderedUI == 1">{{ type.count }}</div> -->
                </div>
            </ng-container>
        </div>
        <!-- righthand side -->
        <div>
            <app-search-filter (searchInputChange)="searchKeyup($event)" (filterClick)="filterHandler($event)" [filterOptions]="filterOptions"></app-search-filter>
        </div>
    </div>
    <div class="content" id="content" (scroll)="scrollHandler($event)">
        <app-manual-register *ngIf="renderedUI === 0 || renderedUI === 2" [renderedUI]="renderedUI" [filteredCatalog]="allInventoryType[selectedType.id].data"></app-manual-register>
        <app-my-inventory *ngIf="renderedUI === 1" [filteredInventory]="allInventoryType[selectedType.id].data"></app-my-inventory>
        <div *ngIf="isLoading" class="loader-container">
            <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div>
            <div class="showing">Showing <span>{{ this.allInventoryType[selectedType.id].data.length }}</span> from <span>{{ this.allInventoryType[selectedType.id].meta.totalRecords }}</span> results</div>
        </div>
    </div>
</div>

<app-model-popup *ngIf="showModelPopup" (closeEvent)="showModelPopup = false"></app-model-popup>