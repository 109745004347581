<section class="inventory-container d-flex flex-column gap-5">
  <div class="d-flex justify-content-between align-items-center">
    <div class="headings-container d-flex flex-column justify-content-between">
      <span class="heading">Master Library</span>
      <span *ngIf="true" class="subheading">Manage your library</span>
    </div>
    <img
      *ngIf="false"
      [src]="'/icon/close.svg'"
      alt="round"
      width="14px"
      height="14px"
    />
    <div class="d-flex align-items-end gap-2 align-self-end" *ngIf="true">
      <div class="search-container d-flex gap-2">
        <img [src]="'/icon/quick-search.png'" alt="quick-search" width="30px"/>
        <input type="search" class="input-search" placeholder="Quick search (⌘K)" />
      </div>
      <button class="new-collection-button d-flex gap-2">
        <img [src]="'/icon/plus.svg'" alt="plus-icon"/>
        <span>New collection</span>
      </button>
      <button>

      </button>
    </div>
  </div>
  <div
    class="d-flex align-items-center justify-content-between master-download"
  >
    <div class="d-flex flex-column gap-4">
      <div class="d-flex gap-4 align-items-center">
        <img
          [src]="'/icon/csvIcon.png'"
          alt="csv-icon"
          height="20px"
        />
        <span>Master .csv file</span>
      </div>
      <span class="master-text"
        >Please download this .csv file in order to create your library. After
        filling up the details, please upload it in the upload.csv below</span
      >
    </div>
    <button class="btn">Download</button>
  </div>
  <div class="d-flex align-items-center justify-content-between gap-4">
    <div class="upload-heading">Upload .csv</div>
    <div class="line container-fluid"></div>
  </div>
  <div class="file-container d-flex flex-column gap-4">
    <div class="file-upload-container">
      <div class="file-drop-text">
        <span>Drop file here or </span>
        <label class="file-input">
          <input type="file" class="d-none" /> Choose File
        </label>
      </div>
      <div class="file-upload-info d-flex flex-column align-items-center">
        <span>Supported format: .csv</span>
        <span>Maximum size: 25 MB</span>
      </div>
    </div>
    <div class="file-progress-container d-flex flex-column gap-4">
      <div
        class="file-progress-info d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center gap-4">
          <img [src]="'/icon/csvIcon.png'" height="18px" />
          <span class="file-name">masterlibrary2.csv</span>
          <span class="file-size">3 MB</span>
        </div>
        <!-- <div class="upload-progress-info">7% . 30 seconds left</div> -->
        <div class="d-flex gap-4">
          <button
            class="btn preview-btn d-flex gap-2 align-items-center"
            (click)="onPreview()"
          >
            Preview
            <img
              [class]="['chevron-right', showPreview ? 'chevron-bottom' : '']"
              [src]="'/icon/arrow-right-01-sharp.svg'"
              height="16px"
            />
          </button>
          <button class="btn delete-btn">Delete</button>
        </div>
      </div>
      <div class="d-flex gap-4 container-fluid">
        <app-preview-uploaded-inventory
          *ngFor="let item of defaultLibrary"
          [iconSrc]="item.type.icon"
          [inventoryType]="item.type.name"
          [inventoryQuantity]="item.count"
        />
      </div>

      <!-- <div
        class="progress-bar d-flex align-items-center container-fluid position-relative"
      >
        <div class="progress-completed" [ngStyle]="{width: '30%'}"></div>
        <img
          class="progress-bar-marker"
          [src]="'/icon/progress-line.svg'"
          alt="progress-bar-marker"
          height="17px"
        />
      </div> -->
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <button class="btn">Cancel</button>
    <button class="btn save-btn" [disabled]="true">Save</button>
  </div>
</section>
