import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-global-toast',
  templateUrl: './global-toast.component.html',
  styleUrls: ['./global-toast.component.scss']
})
export class GlobalToastComponent implements OnInit {
  constructor(private toastService: ToastService) {}
  @Input() messages: any = []
  @Input() toastInfo: any = {
    state: 'success',
    isIcon: true,
    contents: [
      {
        title: '378 drone',
        message: 'models added successfully.'
      },
    ],
  }

  ngOnInit(): void {
    this.toastService.toastState.subscribe((data: any) => {
      this.toastInfo = {
        ...this.toastInfo,
        ...data,
      }
      const newMsg = data.contents
      this.messages.unshift(newMsg)
      setTimeout(() => {
        this.messages = this.messages.filter((msg: any) => msg !== newMsg);
      }, 3000);
    })
  }
}
