import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from '../services/inventory.service';
import { CompatibleList } from '../shared/models/compatible-list.interface';

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})
export class InventoryDetailsComponent implements OnInit{
  @Input() selectedCatalog: any

  constructor(private api: InventoryService) {}

  tabs = [
    { id: 1, name: 'Tech Specs' },
    { id: 2, name: 'Compatibilities' }
  ]
  selectedTab = this.tabs[0]
  compatibles: CompatibleList = {
    category: '',
    data: []
  }

  ngOnInit(): void {
    this.setCategoryTitle()
    this.getcompatibles()
  }

  changeTab(tab: any) {
    this.selectedTab = tab
  }

  setCategoryTitle() {
    let compatibleFor = ''
    const catalogType = this.selectedCatalog.type.toLowerCase()
    if (catalogType === 'drone') {
      compatibleFor = 'payload'
    } else if (catalogType === 'payload') {
      compatibleFor = 'drone'
    }

    this.compatibles.category = `Compatible ${compatibleFor}`
  }

  getcompatibles() {
    this.compatibles.data = []
    const id = this.selectedCatalog._id
    this.api.getCatalogsDetails(id).subscribe({
      next: (response: any) => {
        const compatibles = response.compatibilities
        this.compatibles.data = compatibles.map((compatible: any) => ({
          ...compatible,
          image: compatible.image !== '' && compatible.image !== undefined ? compatible.image : '',
        }))
      },
      error: (error: any) => {
        console.log(error)
      }
    })
  }
}
