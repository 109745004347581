<div class="icon">
  <img [src]="iconSrc" />
</div>
<div class="preview-inventory-info">
  <div class="inventory-type">
    {{ inventoryType }}
  </div>
  <div class="inventory-quantity">
    {{ inventoryQuantity }} {{ inventoryQuantity > 1 ? 'models' : 'model' }}
  </div>
</div>