import { Component } from '@angular/core';
import './config-hsf.js'
@Component({
  selector: 'app-inventory-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fe-inventory-management';
}
