import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { state } from '@app/utility';
// const environmentService = new EnvironmentService();
const inventoryTotal = environment.apiHost + '/inventory/inventory/total';
const postBulkDrone = environment.apiHost + '/inventory/drones/bulk/create';
const postBulkPayload = environment.apiHost + '/inventory/payloads/bulk/create';
const checkBulkDrone = environment.apiHost + '/inventory/drones/bulk/check';
const checkBulkPayload = environment.apiHost + '/inventory/payloads/bulk/check';
const allCatalogs = environment.apiHost + '/inventory/catalogs';
const myInventory = environment.apiHost + '/inventory/inventory';
const catalogFilters = environment.apiHost + '/inventory/catalogs/lookup-data';
const inventoryFilters = environment.apiHost + '/inventory/inventory/lookup-data';
const catalogSearch = environment.apiHost + '/inventory/catalogs/search';
const inventorySearch = environment.apiHost + '/inventory/inventory/search';

const headers =  new HttpHeaders({
    Authorization: 'Bearer ' + state.getDronosToken().value,
    workspaceId: state.getUserInfo().activeWorkspaceId
  });


@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient) { }

  getInventoryCount(): Observable<any> {
    return this.http.get<any>(inventoryTotal, { headers : headers });
  }

  getInventories(params: any = {}): Observable<any> {
    return this.http.get(`${myInventory}`, { headers: headers, params: params })
  }

  getMyInventory(data: any): Observable<any> {
   let params = null
    if (data.keyword == '' && data.type == 'All') {
      params = `types=${data.type}`
    } else {
      params = `keyword=${data.keyword}`
    }
    
    return this.http.get<any>(`${myInventory}?${params}`, { headers : headers });
  }

  postBulkInventory(data: any, type: string): Observable<any> {
    return this.http.post(`${type == 'drone' ? postBulkDrone : postBulkPayload}`, data, { headers : headers });
  }

  getCatalogs(params: any = {}): Observable<any> {
    return this.http.get<any>(allCatalogs, { headers : headers, params: params });
  }

  getCatalogsDetails(id: string): Observable<any> {
    return this.http.get<any>(`${allCatalogs}/${id}`, { headers : headers });
  }

  postCheckDuplicateSerial(data: any, type: string): Observable<any> {
    return this.http.post(type == 'drone' ? checkBulkDrone : checkBulkPayload, data, { headers: headers });
  }

  getCatalogFilters() {
    return this.http.get<any>(`${catalogFilters}`, { headers : headers });
  }

  getInventoryFilters() {
    return this.http.get<any>(`${inventoryFilters}`, { headers : headers });
  }
  
  getCatalogSearch(params: any = {}) {
    return this.http.get<any>(`${catalogSearch}`, { headers : headers, params: params });
  }

  getInventorySearch(params: any = {}) {
    return this.http.get<any>(`${inventorySearch}`, { headers : headers, params: params });
  }
}
