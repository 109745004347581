import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accessory-tab',
  templateUrl: './accessory-tab.component.html',
  styleUrls: ['./accessory-tab.component.scss']
})
export class AccessoryTabComponent {
  @Input() accessories: any;
}
