import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { InventoryHomeComponent } from './inventory-home/inventory-home.component';
import { AddInventoryBulkComponent } from './bulk-register/add-inventory-bulk.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';

const routes: Routes = [
  { path: '', component: InventoryHomeComponent },
  { path: 'add-inventory-bulk', component: AddInventoryBulkComponent },
  { path: 'add-inventory', component: InventoryListComponent },
  { path: 'my-inventory', component: InventoryListComponent },
  { path: 'catalogue', loadChildren: () => import('./master-library/master-library.module').then(m => m.MasterLibraryModule) },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: APP_BASE_HREF, useValue: '/inventory-management' }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
