<div class="inventory-card" [ngClass]="{ pointer: !disabled }" (click)="clickHandler()">
    <div class="image-container">
        <img *ngIf="catalog.image === ''" [src]="'/icon/placeholder.svg' | assetUrl" alt="">
        <img *ngIf="catalog.image !== ''" [src]="catalog.image" alt="">
    </div>
    <div class="details-container" [ngClass]="{ disabled: disabled }">
        <div class="catalog-type" [ngClass]="(catalog.type).toLowerCase()">{{ catalog.type }}</div>
        <div>{{ catalog.model }}</div>
        <div class="tags-container">
            <div class="custom-tag">
                {{ catalog.subtype }}
            </div>
            <div class="custom-tag">
                {{ catalog.brand }}
            </div>
        </div>
    </div>
</div>