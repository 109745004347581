import { Component, Input } from '@angular/core';
import { InventoryService } from 'src/app/services/inventory.service'
import { Router } from '@angular/router';
import { state } from '@app/utility';
import { ToastService } from '../services/toast.service';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { CompatibleList } from '../shared/models/compatible-list.interface';

@Component({
  selector: 'app-manual-register',
  templateUrl: './manual-register.component.html',
  styleUrls: ['./manual-register.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'scale(0.5)' }),
            stagger(1, [animate('0.1s', style({ opacity: 1, transform: 'scale(1)' }))]),
          ],
          { optional: true }
        ),
      ]),
    ]),
    trigger('enterLeaveAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'scale(0.5)' }),
            stagger(1, [animate('0.1s', style({ opacity: 1, transform: 'scale(1)' }))]),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            style({ opacity: 1, transform: 'scale(1)' }),
            stagger(1, [animate('0.1s', style({ opacity: 0, transform: 'scale(0.5)' }))]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class ManualRegisterComponent {
  @Input() filteredCatalog: any = [];
  @Input() catalogType: string = '';
  @Input() renderedUI: number = -1;

  constructor(private router: Router, private api: InventoryService, private toastService: ToastService) {}
  selectedCatalog: any = {}
  isOpenPanel: boolean = false
  isInventoryAdded: boolean = false
  isExpand: boolean = true
  quantityCount: any = 1
  allCards: any = [0]
  inventory: any = []
  formattedInventory: any = []
  validationFailed: boolean = false
  currentCatalogType: string = ''

  expandCloseTab(idx: any) {
    let index =  this.allCards.indexOf(idx);
    if (index !== -1) {
      this.allCards.splice(index, 1);
    }  else {
      this.allCards.push(idx)
    }
  }

  removeIdx(idx: any) {
    if (idx != 0) {
      this.inventory = this.inventory.filter((item:any, index: any) => index != idx)
    }
  }

  checkRequiredFieldIsEmpty() {
    const myPromise = new Promise(resolve => {
      const isReqFieldEmpty = this.inventory.some((item: any) => {
        return item.flightHours === '' || item.serialNumber === '' || item.flightHours === null || item.serialNumber === null
       })
       resolve(!isReqFieldEmpty)
    })
    return myPromise
  }

  inventoryValidation() {
    const myPromise = new Promise(resolve => {
      this.checkRequiredFieldIsEmpty().then(isPass => {
        if (isPass) {
          this.hasDuplicateId(this.inventory).then(noDuplicate => {
            if (noDuplicate) {
              this.formattingInventoryJson()
              this.validateSerialWithDatabase().then(noDBDuplicate => {
                if (noDBDuplicate) {
                  this.validationFailed = false
                  resolve(true)
                }
              })
            }
          })
        } else {
          this.validationFailed = true
        }
      })
    })
    return myPromise
  }

  formattingInventoryJson() {
    this.formattedInventory = this.inventory.map((item: any) => {
      const { isDuplicate, flightHours, ...rest } = item
      const data = {
        ...rest,
        flightHour: item.flightHours?.toString(),
        brand: this.selectedCatalog.brand,
        subtype: this.selectedCatalog.subtype,
        model: this.selectedCatalog.model,
        type: this.selectedCatalog.type,
        catalogId: this.selectedCatalog.id,
        workspaceId: state.getUserInfo().activeWorkspaceId
      }
      return data
    })
  }

  addManualInventory() {
    this.inventoryValidation().then(() => {
      const postData = {
        items: this.formattedInventory
      };
      this.api.postBulkInventory(postData, this.catalogType).subscribe({
        next: (response) => {
          const contents = [{
            title: `${response.length} ${this.currentCatalogType}`,
            message: 'models added successfully.'
          }]
          const toastConfig = {
            state: 'success',
            contents: contents,
          }
          this.toastService.showToast(toastConfig)
          this.router.navigate(['/my-inventory'])
        },
        error: (error) => {
          console.log(error)
        }
      })
    })
  }

  hasDuplicateId(invt: any) {
    const myPromise = new Promise(resolve => {
      let found = false
    const seenIds = new Set();
      for (let obj of invt) {
        if (seenIds.has(obj.serialNumber)) found = true;
        seenIds.add(obj.serialNumber);
      }
      resolve(!found)
    })
    return myPromise
  }

  validateDuplicateSerial(item: any, selectedIndex: any) {
    if (this.inventory.length > 1) {
      let foundIndex = -1
      let found = false
      for (let i = 0; i < this.inventory.length; i++) {
        this.inventory[i].isDuplicate = false
          if (i !== selectedIndex && (item.serialNumber === this.inventory[i].serialNumber)) {
            foundIndex = i
            found = true
          }
      }
      if (found) {
        this.inventory[foundIndex].isDuplicate = true
        this.inventory[selectedIndex].isDuplicate = true
      }
    }
  }
  
  validateSerialWithDatabase() {
    const myPromise = new Promise(resolve => {
      const postData = {
        items: this.formattedInventory
      }
      this.api.postCheckDuplicateSerial(postData, this.catalogType).subscribe({
        next: (response) => {
          resolve(true)
        },
        error: (error) => {
          const errResponse = error.error
          const errMsg = errResponse.message
          const ERR_MSG = 'serial number already registered'
          if (errMsg.toLowerCase() === ERR_MSG) {
            const duplicateSerials = errResponse.serialNumber
            let contents: any = []
            duplicateSerials.forEach((serial: string) => {
              contents.push({
                title: `"${serial}"`,
                message: `: ${errMsg}`
              })
            })
            const toastConfig = {
              state: 'error',
              isIcon: false,
              contents: contents,
            }
            this.toastService.showToast(toastConfig)
          }
        }
      })
    })
    return myPromise
  }

  count(type: string) {
    if (type == 'plus') {
      this.inventory.push({
        nickname: '',
        flightHours: null,
        serialNumber: null,
        certificationInfo: {
          certificateId: '',
          attachment: ''
        },
        isDuplicate: false,
      })
    } else {
      this.inventory.pop()
    }
  }

  goToCatalogDetails(catalog: any) {
    this.selectedCatalog = catalog
    this.currentCatalogType = catalog.type
    this.isOpenPanel = true
  }

  goToForm(catalog: any) {
    const id = catalog._id
    const type = catalog.type.toLowerCase()
    this.router.navigate([`/catalogue/form`, type, id])
  }

  cardClickedHandler(catalog: any) {
    if (this.renderedUI === 0) {
      this.goToCatalogDetails(catalog)
    } else if (this.renderedUI === 2) {
      this.goToForm(catalog)
    }
  }

  closeHandler() {
    this.isOpenPanel = false
    this.currentCatalogType = ''
    this.isInventoryAdded = false
  }

  closeHandlerAdd() {
    this.isOpenPanel = true
    this.isInventoryAdded = false
  }
  
  resetForm() {
    this.inventory = []
    this.count('plus')
    this.allCards = [0]
  }

  addToInventory() {
    this.isOpenPanel = false
    this.isInventoryAdded = true
    this.resetForm()
  }

  numberHandler(event: any, index: any) {
    this.inventory[index].flightHours = event.target.value === '0' ? '' : this.inventory[index].flightHours
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    if (newValue !== event.target.value) {
      this.inventory[index].flightHours = newValue;
    }
  }

  serialNumberHandler(event: any, index: any) {
    this.validateDuplicateSerial(this.inventory[index], index)
  }
}
