<div class="toast-container">
  <div *ngFor="let msg of messages" class="toast-message" [ngClass]="toastInfo.state === 'success' ? 'success' : 'error'" role="alert" aria-live="assertive" aria-atomic="true">
    <div>
      <div class="content-container" *ngFor="let content of toastInfo.contents">
          <div *ngIf="toastInfo.isIcon" class="toastification-icon">
              <img *ngIf="toastInfo.state == 'success'" [src]="'/icon/tick.svg'
              | assetUrl" alt="tick">
              <img class="error" *ngIf="toastInfo.state != 'success'" [src]="'/bulk/close.svg'
              | assetUrl" alt="close">
          </div>
          <div class="toastification-title"> {{ content.title}} </div>
          <div class="toastification-msg">
            {{content.message }}
          </div>
      </div>
    </div>
  </div>
</div>