<div>
    <div class="cancel-upload-container">
        <img [src]="'/icon/Warning-remove.svg'| assetUrl" class="img-size">
        <span class="warning-title">
            Cancel upload
        </span>
        <span class="warning-desc">
            Are you sure you want to continue?
        </span>
        <div class="warning-btn">
            <app-global-btn [btnWidth]="'133px'" [btnHeight]="'37px'" [isIcon]="false" [btnBg]="'none'" [btnBorder]="'none'" [btnText]="'Cancel'" (btnClicked)="closeModal()"></app-global-btn>
            <app-global-btn [btnWidth]="'133px'" [btnHeight]="'37px'" [isIcon]="false" [btnText]="'Confirm'" (btnClicked)="confirmDelete()"></app-global-btn>
        </div>
    </div>
   
</div>
