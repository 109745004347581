<div class="bulk-register-container">
    <div class="flex-1">
        <!-- header section-->
        <div class="header">
            <div class="title">
                BULK ENTRY
            </div>
            <div>
                <img class="close-icon" [src]="'/bulk/close.svg' | assetUrl" alt="" routerLink="/">
            </div>
        </div>
        <!-- information section -->
        <div class="information">
            <div class="main-info">
                <div class="title">
                    <img [src]="'/bulk/xls.svg' | assetUrl" alt="">
                    <div>Inventory.xls file</div>
                </div>
                <div class="subtitle">
                    Please download this .xls file in order to create your library. After filling up the details, please upload it in the upload .xls below.
                </div>
            </div>
            <div>
                <button class="btn custom-dark f-14 btn-lg" (click)="downloadSample()">Download</button>
            </div>
        </div>
        <!-- divider section -->
        <div class="upload-divider">
            <div class="title">
                Upload .xls
            </div>
            <div class="line"></div>
        </div>
        <!-- file upload section -->
        <div *ngIf="!showProgress && !hasCorruptedData">
            <ngx-dropzone [multiple]="false" (change)="onSelect($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="custom-dropzone" class="custom-dropzone"  (dragover)="dragEvent()" (dragleave)="dragLeave()">
                <ngx-dropzone-label class="label">
                    <div>Drop file here or <u>Choose file</u></div>
                    <div class="sub-label mt-4">Supported format: .xls</div>
                    <div class="sub-label">Maximum size : 25 MB</div>
                </ngx-dropzone-label>
            </ngx-dropzone>
        </div>
        <!-- progress section -->
        <div class="upload-progress" *ngIf="showProgress">
            <div class="d-flex justify-content-between">
                <div class="progress-title">
                    <img [src]="'/bulk/csv_2.svg'| assetUrl">
                    <span class="file-name"> {{ files[0].name }}</span>
                    <span class="file-size"> {{ changeFileSize(files[0].size).toFixed(2) }} MB </span>
                </div>
                <div class="progress-title" *ngIf="uploadProgress != null">
                    <span class="file-name">{{ uploadProgress }} %</span>
                    <div class="dot"></div>
                    <span class="file-size"> {{ timeLeft }}</span>
                    <img [src]="'/icon/close-bold.svg'| assetUrl" class="close-icon" (click)="resetAllView()">
                </div>
                <div class="progress-title" *ngIf="uploadProgress == null">
                    <button class="btn custom-dark f-14 btn-lg btn-preview" (click)="showPreview = !showPreview">Preview
                        <img [src]="(showPreview ? '/icon/chevron-down.svg' : '/icon/chevron-right.svg') | assetUrl">
                    </button>
                    <div class="delete-btn" (click)="deleteFile()">Delete</div>
                </div>
            </div>
            <div class="d-flex mt-3" *ngIf="uploadProgress != null">
                <div class="progress" style="--bs-progress-bar-bg: #00F0FF; --bs-progress-bg: rgba(92, 217, 239, 0.5) !important; width: 98%;">
                    <div class="progress-bar" role="progressbar"  [attr.aria-valuenow]="uploadProgress" [style.width]="uploadProgress + '%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div *ngIf="showPreview" class="mt-3">
                <span class="file-size">Preview Content</span>
                <div class="all-preview">
                    <div *ngFor="let item of masterList">
                        <div class="preview-list">
                        <!-- <div class="desc"> -->
                            <img [src]="'/icon/' + item.icon + '.svg'| assetUrl">
                            <div class="preview-desc">
                                <span class="file-name"> {{ item.type }}</span>
                                <span class="file-size"> {{ item.count }} models</span>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="hasCorruptedData && !showProgress" class="upload-progress">
            <div class="d-flex justify-content-between align-items-center">
                <div class="corrupt-header">
                    <img [src]="'/icon/alert-warning.svg' | assetUrl" alt="">
                    <div class="corrupt-title">Error in uploaded file</div>
                    <div class="corrupt-desc">{{ corruptedDataDetails.length }} found<span *ngIf="corruptedDataDetails.length > 1">s</span></div>
                    <button class="btn custom-dark f-14 btn-lg btn-preview" (click)="showCorrupted = !showCorrupted">
                        <span *ngIf="!showCorrupted">View more</span>
                        <span *ngIf="showCorrupted">View less</span>
                        <img [src]="(showCorrupted ? '/icon/chevron-down.svg' : '/icon/chevron-right.svg') | assetUrl">
                    </button>
                </div>
                <div>
                    <app-global-btn [isIcon]="false" [btnText]="'Upload again'" (btnClicked)="resetAllView()"></app-global-btn>
                </div>
            </div>
            <div *ngIf="showCorrupted" class="d-flex flex-column gap-12 mt-3">
                <ng-container *ngFor="let corrupt of corruptedDataDetails">
                    <div class="corrupt-preview-text">
                        <ng-container *ngIf="corrupt.type === 'data-format'">{{ corrupt.message }}</ng-container>
                        <ng-container *ngIf="corrupt.type === 'duplicate-serial'">Serial Number : <span>"{{ corrupt.message }}"</span> already exist in file.</ng-container>
                        <ng-container *ngIf="corrupt.type === 'duplicate-serial-database'">Serial Number : <span>"{{ corrupt.message }}"</span> already exist in database.</ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- buttons section -->
    <div class="buttons-container">
        <div>
            <button class="btn custom-dark btn-lg" routerLink="/">Cancel</button>
        </div>
        <div>
            <button class="btn custom-primary btn-lg" [disabled]="!ableToSave" (click)="save()">Save</button>
        </div>
    </div>
</div>