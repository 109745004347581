<div class="tech-spec-content">
    <div>Brand</div>
    <div>{{ catalog.brand }}</div>
    <div>Model</div>
    <div>{{ catalog.model }}</div>
    <div>Type</div>
    <div>{{ catalog.type }}</div>
    <ng-container *ngFor="let spec of catalog.specs">
        <div style="text-transform: capitalize;">
            {{ spec.key }}
        </div>
        <div>
            {{ spec.value }}
        </div>
    </ng-container>
</div>