<div class="home-container">
   <div class="home-content">
    <h2 class="home-title">Inventory Management</h2>
    <div class="top-menu">
        <span class="home-desc">Manage your inventory.</span>
        <div class="right-menu">
              <div style="position: relative;">
            <app-global-btn [btnText]="'New'" (btnClicked)="addNewBtn($event)">
            </app-global-btn>
            <div class="btn-dropdown" *ngIf="showOptions">
                <span class="dropdown-text" routerLink="/add-inventory-bulk">Using .xls (bulk)</span>
                <span class="dropdown-text" routerLink="/add-inventory">Manual Entry (one)</span>
            </div>
        </div>
        </div>
    </div>
    <div class="list-cards-container">
        <div class="list-cards" *ngFor="let item of cardList" (click)="navigateToMyInventory(item.text)">
            <img [src]="item.img | assetUrl" class="img-size" alt="catalog-thumbnail">
            <div class="card-text">
                <div class="card-gradient"></div>
                <span class="card-title"> {{ item.total }} {{ item.text}}</span>
                <div class="btn-add-new" (click)="navigateToManualEntry(item.text)">ADD NEW</div>
                <div class="card-type">
                    <div class="inner-top"></div>
                    {{ item.text }}
                </div>
            </div>
        </div>
    </div>
   </div>
   <!-- sample to use toast -->
   <app-global-toast *ngIf="showToast" [toastInfo]="toastConfig"></app-global-toast>
</div>