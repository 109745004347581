import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-preview-uploaded-inventory',
  templateUrl: './preview-uploaded-inventory.component.html',
  styleUrls: ['./preview-uploaded-inventory.component.scss']
})
export class PreviewUploadedInventoryComponent {
  @Input() iconSrc!: string;
  @Input() inventoryType!: string;
  @Input() inventoryQuantity!: number;

  @HostBinding('class') componentClass: string = 'preview-inventory-container'
}
