import { Component, Input } from '@angular/core';
import { InventoryService } from 'src/app/services/inventory.service';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-my-inventory',
  templateUrl: './my-inventory.component.html',
  styleUrls: ['./my-inventory.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'scale(0.5)' }),
            stagger(1, [animate('0.1s', style({ opacity: 1, transform: 'scale(1)' }))]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class MyInventoryComponent {
  @Input() filteredInventory: any = []
  @Input() inventoryType: string = 'All'
  selectedCatalog: any = {}
  isOpenPanel: boolean = false
  isExpand: boolean = true

  constructor(private api: InventoryService) {}

  goToInventoryDetails(inventory: any) {
    this.selectedCatalog = {
      ...inventory.catalogInfo,
      image: inventory.catalogInfo.image !== '' && inventory.catalogInfo.image ? inventory.catalogInfo.image : '',
    }
    this.isOpenPanel = true
  }

  closeHandler() {
    this.isOpenPanel = false
  }

  closeHandlerAdd() {
    this.isOpenPanel = true
  }
}
