import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss']
})
export class SidepanelComponent {
  @Input() headerTitle = 'Drone Details'
  @Input() footerBtnText = 'Add to My Inventory'
  @Input() hideFooter = false
  @Input() hasCancel = false

  @Output() closePanel = new EventEmitter<boolean>();
  @Output() btnClicked = new EventEmitter<boolean>();

  closeHandler() {
    const sidepanelEl = document.querySelector('.sidepanel-container')
    sidepanelEl?.classList.remove('slide-in')
    sidepanelEl?.classList.add('slide-out')
    setTimeout(() => {
      this.closePanel.emit(true)
    }, 300)
  }

  btnHandler() {
    this.btnClicked.emit(true)
  }
}
