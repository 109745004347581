import { Component } from '@angular/core';

@Component({
  selector: 'app-preview-inventory',
  templateUrl: './preview-inventory.component.html',
  styleUrls: ['./preview-inventory.component.scss']
})
export class PreviewInventoryComponent {
  modelsQuantity: number = 0;
  modelType: string = 'EQUIPMENT';
}
