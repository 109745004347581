import { Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-cancel-upload',
  templateUrl: './cancel-upload.component.html',
  styleUrls: ['./cancel-upload.component.scss']
})
export class CancelUploadComponent {
  constructor ( private aModalService: NgbActiveModal,){
    
  }
  closeModal() {
    this.aModalService.close();
  }

  confirmDelete() {
    this.aModalService.close('confirm');
  }
}
