import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { PreviewUploadedInventoryComponent } from './components/preview-uploaded-inventory/preview-uploaded-inventory.component';
import { PreviewInventoryComponent } from './components/preview-inventory/preview-inventory.component';
import { InventoryHomeComponent } from './inventory-home/inventory-home.component';
import { HttpClientModule } from '@angular/common/http';
import { AssetUrlPipe } from './pipe/asset-url.pipe';
import { GlobalBtnComponent } from './components/global-btn/global-btn.component';
import { GlobalToastComponent } from './components/global-toast/global-toast.component';
import { AddInventoryBulkComponent } from './bulk-register/add-inventory-bulk.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ManualRegisterComponent } from './manual-register/manual-register.component';
import { CancelUploadComponent } from './cancel-upload/cancel-upload.component';
import { SidepanelComponent } from './components/sidepanel/sidepanel.component';
import { TechSpecTabComponent } from './components/tech-spec-tab/tech-spec-tab.component';
import { FormsModule } from '@angular/forms';
import { MyInventoryComponent } from './my-inventory/my-inventory.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastService } from './services/toast.service';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { CatalogCardComponent } from './components/catalog-card/catalog-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InventoryDetailsComponent } from './inventory-details/inventory-details.component';
import { AccessoryTabComponent } from './components/accessory-tab/accessory-tab.component';
import { ModelPopupComponent } from './model-popup/model-popup.component';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PreviewUploadedInventoryComponent,
    PreviewInventoryComponent,
    InventoryHomeComponent,
    AssetUrlPipe,
    GlobalBtnComponent,
    AddInventoryBulkComponent,
    GlobalToastComponent,
    ManualRegisterComponent,
    CancelUploadComponent,
    SidepanelComponent,
    TechSpecTabComponent,
    MyInventoryComponent,
    SearchFilterComponent,
    InventoryListComponent,
    CatalogCardComponent,
    InventoryDetailsComponent,
    AccessoryTabComponent,
    ModelPopupComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgxDropzoneModule,
    FormsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule,
    BrowserAnimationsModule,
  ],
  providers: [ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
