import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-global-btn',
  templateUrl: './global-btn.component.html',
  styleUrls: ['./global-btn.component.scss']
})
export class GlobalBtnComponent {
  @Input() imgIcon: string = '/icon/add.svg'
  @Input() textColor: string = 'white'
  @Input() btnText: string = 'Btn'
  @Input() btnBorder: string = '#00F0FF'
  @Input() btnBg: string = 'rgba(92, 217, 239, 0.50)'
  @Input() btnHeight: string = '36px'
  @Input() btnWidth: string = 'auto'
  @Input() isIcon: boolean = true
  @Output() btnClicked = new EventEmitter<boolean>();
  @Input() isLoading: boolean = false

  clickBtn() {
    this.btnClicked.emit(true)
  }
}
