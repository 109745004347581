<div class="sidepanel-container">
    <div class="image-thumbnail">
        <img [src]="selectedCatalog.image === '' ? ('/icon/placeholder.svg' | assetUrl) : selectedCatalog.image" alt="">
    </div>
    <div class="tabs">
        <ng-container *ngFor="let tab of tabs">
            <div class="tab-item" [ngClass]="selectedTab.id === tab.id ? 'active' : ''" (click)="changeTab(tab)">
                {{ tab.name }}
            </div>
        </ng-container>
    </div>
    <div class="scrollable">
        <app-tech-spec-tab *ngIf="selectedTab.id === 1" [catalog]="selectedCatalog"></app-tech-spec-tab>
        <app-accessory-tab *ngIf="selectedTab.id === 2" [accessories]="compatibles"></app-accessory-tab>
    </div>
</div>