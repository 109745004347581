<!-- content section -->
<div class="content-container" [@enterAnimation]="filteredCatalog.length">
    <ng-container *ngIf="filteredCatalog.length < 1">No results found.</ng-container>
    <ng-container *ngFor="let catalog of filteredCatalog">
        <app-catalog-card class="item-card-ref" [catalog]="catalog" (cardClicked)="cardClickedHandler(catalog)"></app-catalog-card>
    </ng-container>
</div>
<!-- sidepanel view inventory details -->
<app-sidepanel *ngIf="isOpenPanel" class="view-details" (btnClicked)="addToInventory()"
    (closePanel)="closeHandler()" [headerTitle]="'Drone Details'" [footerBtnText]="'Add to My Inventory'" [hasCancel]="true">
    <app-inventory-details [selectedCatalog]="selectedCatalog"></app-inventory-details>
</app-sidepanel>
<!-- sidepanel add inventory -->
<app-sidepanel *ngIf="isInventoryAdded" class="view-details" (btnClicked)="addManualInventory()"
    (closePanel)="closeHandlerAdd()" [headerTitle]="'Confirm Selection'" [footerBtnText]="'Confirm'" [hasCancel]="true"
    style="padding: 0px 12px;">
    <!-- content over here -->
    <div class="sidepanel-container">
        <span class="manual-subtitle">Enter quantity of your inventory.</span>
        <div class="quantity-container">
            <span class="title wht">Quantity</span>
            <div class="counter">
                <div class="counter-icon" [ngClass]="{ 'disabled' : inventory.length === 1 }" (click)="inventory.length > 1 ? count('neg') : null">
                    <i class="fa-solid fa-minus fa-sm wht"></i>
                </div>
                <div class="number wht">{{ inventory.length }} </div>
                <div class="counter-icon" [ngClass]="{ 'disabled' : inventory.length === 10 }" (click)="inventory.length < 10 ? count('plus') : null">
                    <i class="fa-solid fa-plus wht"></i>
                </div>
            </div>
        </div>
        <div class="horizontal-line"></div>
        <div class="scrollable" [@enterLeaveAnimation]="inventory.length">
            <div *ngFor="let item of inventory; let i = index">
                <div class="component">
                    <div class="header">
                        <div class="left">
                            <div class="img-size">
                                <img *ngIf="selectedCatalog.image === ''" [src]="'/icon/placeholder.svg' | assetUrl" alt="">
                                <img *ngIf="selectedCatalog.image !== ''" [src]="selectedCatalog.image" alt="">
                            </div>
                            <span class="wht">{{ selectedCatalog.model }}</span>
                        </div>
                        <div class="right">
                            <img [src]="'/icon/chevron-up.svg' | assetUrl" alt="" class="pointer" *ngIf="allCards.includes(i)" (click)="expandCloseTab(i)">
                            <img [src]="'/icon/chevron-down.svg' | assetUrl" alt="" class="pointer" *ngIf="!allCards.includes(i)" (click)="expandCloseTab(i)">
                            <div *ngIf="i !== 0" [ngClass]="allCards.includes(i) ? 'del-btn-disabled' : 'del-btn'" (click)="allCards.includes(i) ? null : removeIdx(i)">Delete</div>
                        </div>
                    </div>
                    <div class="content" *ngIf="allCards.includes(i)">
                        <span class="manual-subtitle">Complete the profile</span>
                        <div class="mt-3">
                            <div class="quantity-title wht">Nickname (if any)</div>
                            <div class="quantity-input">
                                <input placeholder="Enter nickname" type="text" [(ngModel)]="item.nickname">
                            </div>
                            <div class="quantity-title wht">Serial Number</div>
                            <div class="quantity-input">
                            <input placeholder="Enter serial number" type="text" [(ngModel)]="item.serialNumber" (keyup)="serialNumberHandler($event, i)">
                            </div>
                            <span class="text-danger" *ngIf="item.serialNumber == '' || validationFailed">Please enter serial number.</span>
                            <span class="text-danger" *ngIf="item.isDuplicate">Serial number is repetitive.</span>
                            <div class="quantity-title wht">Flight Hours </div>
                            <div class="quantity-input">
                            <input placeholder="Enter flight hours" type="text" [(ngModel)]="item.flightHours" (keyup)="numberHandler($event, i)">
                            </div>
                            <span class="text-danger" *ngIf="item.flightHours == '' || validationFailed">Please enter flight hours.</span>
                            <!-- <div class="quantity-title wht">SIRIM/MCMC Number</div>
                            <div class="quantity-input">
                            <input placeholder="Enter SIRIM/MCMC number" type="text" [(ngModel)]="item.certificationInfo.certicateId">
                            </div>
                            <div class="quantity-title wht">Upload SIRIM/MCMC Attachment</div>
                            <div class="d-flex align-items-center">
                                <div class="quantity-input">
                                    <input placeholder="Click button to upload" disabled>
                                </div>
                                <div class="upload-btn">
                                    <i class="fa-solid fa-arrow-up-from-bracket icon-color"></i>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-sidepanel>