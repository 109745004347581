import { Component } from '@angular/core';
import { read, utils, WorkBook } from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CancelUploadComponent } from '../cancel-upload/cancel-upload.component';
import { InventoryService } from '../services/inventory.service';
import { state } from '@app/utility';
import { ToastService } from '../services/toast.service';
import { Router } from '@angular/router';

type AOA = any[][];

@Component({
  selector: 'app-add-inventory-bulk',
  templateUrl: './add-inventory-bulk.component.html',
  styleUrls: ['./add-inventory-bulk.component.scss']
})
export class AddInventoryBulkComponent {
  constructor(private toastService: ToastService, private modalService: NgbModal, private inventoryService: InventoryService, private router: Router) { }
  files: File[] = [];
  uploadProgress: any = 0;
  showProgress: Boolean = false;
  showPreview: Boolean = false;
  timeLeft: string | null = null; // Add this to store time left
  interval: any;
  masterList: any = []
  allowedSheet: any = {
    // names: ['Drone', 'Payload', 'Equipment', 'Asset'],
    names: ['Drone'],
    headers: {
      drone: ['brand', 'model', 'type', 'subtype', 'flightHour', 'serialNumber', 'nickName'],
      payload: ['brand', 'model', 'type', 'subtype', 'flightHour', 'serialNumber', 'nickName'],
      equipment: ['brand', 'model', 'type', 'subtype', 'flightHour', 'serialNumber', 'nickName'],
      asset: ['brand', 'model', 'type', 'subtype', 'flightHour', 'serialNumber', 'nickName'],
    },
  }
  duplicateSerials: any = []
  hasCorruptedData: boolean = false
  corruptedDataDetails: any = []
  showCorrupted: boolean = false
  ableToSave: boolean = false

  resetAllView() {
    this.showPreview = false
    this.hasCorruptedData = false
    this.showCorrupted = false
    this.ableToSave = false
    this.cancelProgressBar()
  }

  resetAllData() {
    this.files = []
    this.uploadProgress = 0
    this.timeLeft = null
    this.masterList = []
    this.duplicateSerials = []
    this.corruptedDataDetails = []
  }

  cancelProgressBar() {
    this.showProgress = false
    clearInterval(this.interval)
  }

  deleteFile() {
    const modalRef = this.modalService.open(CancelUploadComponent, {
      centered: true,
      windowClass: 'view-receipt',
      backdropClass: 'view-receipt-static',
      backdrop: 'static',
      size: 'sm',
    });

    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.resetAllView()
      }
    })
  }

  onSelect(event: any) {
    this.resetAllData()
    // this.files.push(...event.addedFiles); //for multiple files
    this.files = event.addedFiles
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(this.files[0]);
    this.simulateFileProcessing();
    reader.onload = (e: any) => {
      const ab: ArrayBuffer = e.target.result;
      const wb: WorkBook = read(ab);
      this.processSheet(wb)
    };
  }

  processSheet(wb: any) {
    const wsnames = wb.SheetNames
    const allowed = wsnames.filter((name: string) => this.allowedSheet.names.includes(name))
    this.hasCorruptedData = allowed.length < 1
    allowed.forEach((sheetName: string) => {
      const ws = wb.Sheets[sheetName]
      const sheetJson = <AOA>(utils.sheet_to_json(ws));
      this.sheetJsonValidation(sheetName, sheetJson)
      this.formattingSheetJson(sheetName, sheetJson)
    })
    if (!this.hasCorruptedData) {
      this.checkDuplicateSerialFromDatabase()
    } else {
      this.cancelProgressBar()
    }
  }

  formattingSheetJson(sheetName: string, sheetJson: any) {
    const mappedSheetJson = sheetJson.map((item: any) => ({
      ...item,
      catalogId: '',
      flightHour: item.flightHour.toString(),
      certificationInfo: {
        certificateId: item.certificateID.toString(),
        attachment: '',
      },
      workspaceId: state.getUserInfo().activeWorkspaceId,
      others: {
        none: '',
      }
    }))
    const data = {
      icon: sheetName.toLowerCase(),
      type: sheetName,
      count: sheetJson.length,
      data: mappedSheetJson,
    }
    this.masterList.push(data)
  }

  sheetJsonValidation(sheetName: string, sheetJson: any) {
    // ---- check header format -----
    const wrongHeaderFormat = this.checkHeaderFormat(sheetName, sheetJson)
    if (wrongHeaderFormat) {
      this.hasCorruptedData = true
      this.corruptedDataDetails.push({
        type: 'data-format',
        message: 'Wrong data format.'
      })
      this.cancelProgressBar()
    }

    // ----- check duplicate serial number -----
    const hasDuplicateSerial = this.checkDuplicateSerial(sheetName, sheetJson)
    if (hasDuplicateSerial) {
      this.hasCorruptedData = true
      this.duplicateSerials.forEach((serial:string) => {        
        this.corruptedDataDetails.push({
          type: 'duplicate-serial',
          message: serial,
        })
      })
      this.cancelProgressBar()
    }
  }

  checkDuplicateSerialFromDatabase() {
    this.masterList.forEach((item: any) => {
      const data = {
        items: item.data
      }
      this.inventoryService.postCheckDuplicateSerial(data, 'drone').subscribe({
        next: (response) => {},
        error: (error) => {
          this.hasCorruptedData = true
          const errResponse = error.error
          const errMsg = errResponse.message
          const ERR_MSG = 'serial number already registered'
          if (errMsg.toLowerCase() === ERR_MSG) {
            const duplicateSerials = errResponse.serialNumber
            duplicateSerials.forEach((serial:string) => {        
              this.corruptedDataDetails.push({
                type: 'duplicate-serial-database',
                message: serial,
              })
            })
          }
          this.cancelProgressBar()
        }
      })
    })
  }

  checkHeaderFormat(wsname: string, sheetJson: any) {
    if (sheetJson.length < 1) {
      return true
    }
    let sheetName = wsname.toLowerCase()
    const res = sheetJson.filter((sheet: any) => {
      const ObjSheet = Object.keys(sheet)
      return !this.allowedSheet.headers[sheetName].every((key: any) => ObjSheet.includes(key))
    })
    if (res.length > 0) {
      return true
    } else {
      return false
    }
  }

  checkDuplicateSerial(sheetName: string, sheetJson: any) {
    let serialNumberSet = new Set()
    let duplicates: any = []
    sheetJson.forEach(((item: any) => {
      if (serialNumberSet.has(item.serialNumber)) {
        duplicates.push(item.serialNumber)
        this.duplicateSerials.push(item.serialNumber)
      } else {
        serialNumberSet.add(item.serialNumber)
      }
    }))
    if (duplicates.length > 0) {
      return true
    } else {
      return false
    }
  }

  simulateFileProcessing() {
    this.showProgress = true
    this.uploadProgress = 0;
    const updateRate = 1000; // 1 second
    const progressIncrement = 10;
    this.interval = setInterval(() => {
      this.uploadProgress = this.uploadProgress as number + 10;
      this.calculateTimeLeft(this.uploadProgress, progressIncrement, updateRate);  // Calculate time left
      if (this.uploadProgress >= 100) {
        clearInterval(this.interval);
        // Do any post-processing here if needed
        setTimeout(() => {
          this.uploadProgress = null;  // Hide progress bar
          this.timeLeft = null;
          this.ableToSave = this.uploadProgress === null && !this.hasCorruptedData
        }, 1500);  // Hide progress bar after 1 second
      }
    }, 500);  // Update every half-second
  }

  calculateTimeLeft(currentProgress: number, increment: number, rate: number) {
    const percentageLeft = 100 - currentProgress;
    const updatesLeft = percentageLeft / increment;
    const timeLeftInSeconds = updatesLeft * (rate / 1000); // Convert rate to seconds
    if (timeLeftInSeconds >= 60) {
      const minutes = Math.floor(timeLeftInSeconds / 60);
      const seconds = timeLeftInSeconds % 60;
      this.timeLeft = `${minutes} min ${seconds} sec left`;
    } else {
      this.timeLeft = `${timeLeftInSeconds} sec left`;
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  dragEvent() {
    this.handleDropzoneStyle('drag')
  }

  dragLeave() {
    this.handleDropzoneStyle('end')
  }

  changeFileSize(size: any) {
    return size / (1024 * 1024)
  }
  handleDropzoneStyle(state: string) {
    const customDropzoneEl = document.getElementById('custom-dropzone')
    if (state === 'drag') {
      customDropzoneEl?.classList.add('drag-over')
    } else {
      customDropzoneEl?.classList.remove('drag-over')
    }
  }

  downloadSample() {
    const filePath = 'https://dev.dronos.ai/public/files/inventory.xlsx'
    const a = document.createElement('a')
    a.href = filePath
    a.setAttribute('download', '')
    a.click()
    a.remove()
  }

  save() {
    // post bulk drone only
    const data = {
      items: this.masterList[0].data
    }
    this.inventoryService.postBulkInventory(data, 'drone').subscribe({
      next: (response) => {
        const contents = this.masterList.map((item: any) => ({
          title: `${item.count} ${item.type.toLowerCase()}`,
          message: 'models added successfully.'
        }))
        const toastConfig = {
          state: 'success',
          contents: contents,
        }
        this.toastService.showToast(toastConfig)
        this.router.navigate([''])
      },
      error: (error) => {
        console.log(error)
      }
    })
  }
}
