import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-spec-tab',
  templateUrl: './tech-spec-tab.component.html',
  styleUrls: ['./tech-spec-tab.component.scss']
})
export class TechSpecTabComponent implements OnInit {
  @Input() catalog: any;

  ngOnInit(): void {
  }
}
