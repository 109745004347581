<div class="model-popup-container">
  <div>
    <img (click)="closeModelPopup()" class="close-icon pointer" [src]="'/bulk/close.svg' | assetUrl" alt="">
  </div>
  <div class="title">What do you want to add today?</div>
  <div class="list-card-container">
    <ng-container *ngFor="let model of models">
      <div class="list-cards" (click)="goToForm(model)">
        <img [src]="'/img/' + model + '.png' | assetUrl" class="img-size" alt="catalog-thumbnail">
        <div class="card-text">
          <div class="card-gradient"></div>
          <div class="card-type">
            <div class="inner-top"></div>
            {{ model }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>