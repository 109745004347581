import { Component } from '@angular/core';

const INVENTORY_TYPE = {
  DRONE: {
    name: 'Drone',
    icon: '/icon/drone-icon.svg'
  },
  PAYLOAD: {
    name: 'Payload',
    icon: '/icon/payload-icon.svg'
  },
  EQUIPMENT: {
    name: 'Equipment',
    icon: '/icon/equipment-icon.svg'
  },
  ASSET: {
    name: 'Asset',
    icon: '/icon/asset-icon.svg'
  },
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  uploadInProgress: boolean = false;
  // uploadCompleted: boolean;
  // currentProgress = 100 will mark it as completed no need to make a separate flag
  showPreview: boolean = false;
  currentProgress: number = 0;

  defaultLibrary = [
    {
      type: INVENTORY_TYPE.DRONE,
      count: 378
    },
    {
      type: INVENTORY_TYPE.PAYLOAD,
      count: 34
    },
    // {
    //   type: INVENTORY_TYPE.EQUIPMENT,
    //   count: 25
    // },
    // {
    //   type: INVENTORY_TYPE.ASSET,
    //   count: 20
    // },
  ]

  onPreview() {
    this.showPreview = !this.showPreview;
  }

}
