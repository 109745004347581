<div class="accessory-tab-container">
    <div class="category-title">
        {{ accessories.category }}
    </div>
    <div class="accessory-item-container">
        <div *ngIf="accessories.data.length < 1">No result.</div>
        <ng-container *ngFor="let item of accessories.data">
            <app-catalog-card [catalog]="item" [disabled]="true"></app-catalog-card>
        </ng-container>
    </div>
</div>