import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit {
  @Input() searchInput = ''
  @Input() filterOptions: any = {}
  @Output() searchInputChange = new EventEmitter<any>();
  @Output() filterClick = new EventEmitter<any>();

  showFilterDrop = false

  dropdownList: Array<string> = [];
  selectedCategory: Array<string> = [];
  selectedBrand: Array<string> = [];
  selectedModel: Array<string> = [];
  storeCategories: Array<string> = [];
  storeBrands: Array<string> = [];
  storeModels: Array<string> = [];
  dropdownSettings: Object = {};
  debounceTimeout: any = null

  totalActiveFilter = 0

  ngOnInit(): void { 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      // itemsShowLimit: 3,
      enableCheckAll: false,
      allowSearchFilter: false
    };
  }

  keyupHandler(event: any) {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(() => {
      this.searchInputChange.emit(this.searchInput)
    }, 500)
  }

  toggleFilter() {
    setTimeout(() => {
      this.selectedCategory = this.storeCategories
      this.selectedBrand = this.storeBrands
      this.selectedModel = this.storeModels
      this.showFilterDrop = !this.showFilterDrop
    })
  }

  clearFilter() {
    this.selectedCategory = []
    this.selectedBrand = []
    this.selectedModel = []
  }

  executeFilter() {
    this.totalActiveFilter = 0
    if (this.selectedCategory.length > 0) {
      this.totalActiveFilter += 1
    }
    if (this.selectedBrand.length > 0) {
      this.totalActiveFilter += 1
    }
    if (this.selectedModel.length > 0) {
      this.totalActiveFilter += 1
    }

    this.storeCategories = this.selectedCategory
    this.storeBrands = this.selectedBrand
    this.storeModels = this.selectedModel

    this.toggleFilter()
    const filterData = {
      subtypes: this.storeCategories,
      brands: this.storeBrands,
      models: this.storeModels,
      activeFilter: this.totalActiveFilter
    }

    this.filterClick.emit(filterData)
  }
}
