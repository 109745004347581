import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryService } from 'src/app/services/inventory.service'

@Component({
  selector: 'app-inventory-home',
  templateUrl: './inventory-home.component.html',
  styleUrls: ['./inventory-home.component.scss']
})
export class InventoryHomeComponent {
  constructor(private router: Router, private _location: Location, private api: InventoryService) {
    const navigationState = this.router.getCurrentNavigation()?.extras.state
    if (navigationState != undefined) {
      this.showToast = navigationState!['showSuccessToast'] !== undefined ? navigationState!['showSuccessToast'] : false
      this.toastConfig = navigationState!['toastConfig'] !== undefined ? navigationState!['toastConfig'] : false
      this._location.replaceState('', '')
    }

  }

  cardList: any = [{
    img: '/img/drone.png',
    total: '0',
    text: 'Drone',
    btn: '/img/btn-drone.png'
  },
  {
    img: '/img/payload.png',
    total: '0',
    text: 'Payload',
    btn: '/img/btn-payload.png'
  },
  // {
  //   img: '/img/component.png',
  //   total: '0',
  //   text: 'Component',
  //   btn: '/img/btn-asset.png'
  // },
  // {
  //   img: '/img/equipment.png',
  //   total: '0',
  //   text: 'Equipment',
  //   btn: '/img/btn-equipment.png'
  // }
]

  showOptions: boolean = false
  showToast: boolean = false
  toastConfig: any = {}

  ngOnInit(): void {
   this.getInventoryCount()
  }

  navigateToMyInventory(item: string) {
    this.router.navigate(['/my-inventory'], { state: { type: item }})
  }

  navigateToManualEntry(title: string) {
    this.router.navigate(['/add-inventory'], { state: { type: title }})
  }

  getInventoryCount() {
    this.api.getInventoryCount().subscribe(
      (response) => {
        this.cardList[0].total = response.Drone
        this.cardList[1].total = response.Payload

      },
      (error) => {
        console.error('Error:', error);
      }
    )
   
  }

  addNewBtn(val: any) {
    this.showOptions = !this.showOptions
  }


}
