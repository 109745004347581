import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryService } from '../services/inventory.service';
interface FilterData {
  keyword: string,
  subtypes: Array<string>,
  brands: Array<string>,
  models: Array<string>,
  activeFilter: number,
}

interface AllInventoryType {
  id: number,
  name: string,
  count: number,
  data: Array<string>,
  meta: Pagination,
}

interface SelectedType {
  id: number,
  name: string,
  count?: number,
}

interface Pagination {
  prevCursor: string,
  nextCursor: string,
  totalRecords: number,
}
@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})

export class InventoryListComponent {
  UIList: Array<string> = ['add-inventory', 'my-inventory', 'catalogue']
  renderedUI: number = -1
  allInventory: Array<string> = []
  filterOptions: Object = {}
  activeFilter: number = 0
  selectedFilter: Object = {}
  searchInput: string = ''
  allInventoryType: Array<AllInventoryType> = [
    {
      id: 0,
      name: 'All',
      count: 0,
      data: [],
      meta: {
        prevCursor: '',
        nextCursor: '',
        totalRecords: 0
      }
    }, {
      id: 1,
      name: 'Drone',
      count: 0,
      data: [],
      meta: {
        prevCursor: '',
        nextCursor: '',
        totalRecords: 0
      }
    }, {
      id: 2,
      name: 'Payload',
      count: 0,
      data: [],
      meta: {
        prevCursor: '',
        nextCursor: '',
        totalRecords: 0
      }
    }
  ]
  selectedType: SelectedType = {
    id: 0,
    name: 'All'
  }
  pageLimit: number = 20
  isLoading: boolean = false
  showModelPopup: boolean = false

  constructor(private router: Router, private api: InventoryService) {
    const item = this.router.getCurrentNavigation()?.extras.state?.['type']
    switch (item) {
      case 'Drone':
        this.selectedType.id = 1
        this.selectedType.name = item
        break
      case 'Payload':
        this.selectedType.id = 2
        this.selectedType.name = item
        break
      default:
        // nanti tambah untuk yang lain
    }

    const url = this.router.url
    this.renderedUI = this.UIList.findIndex((item: string) => {
      return url.includes(item)
    })
  }

  addNewClicked() {
    this.showModelPopup = true
  }

  ngOnInit() {
    this.getFilterOptions()
    this.getAllInventory(this.selectedType.id)
  }

  scrollToTop() {
    document.getElementById('content')?.scrollTo(0,0) //scroll to top
  }

  toolbarClicked(selected: SelectedType) {
    if (this.selectedType.id !== selected.id) {
      this.scrollToTop()
      this.selectedType = selected
      this.allInventoryType[this.selectedType.id].data = [] // reset for selected tab
      this.getAllInventory(this.selectedType.id)
    }
  }

  getAllInventory(tabIndex: number = 0, params = {}, loadMore = false) {
    const allParam = {
      pageLimit: this.pageLimit,
      withCounts: true,
      type: this.selectedType.name,
      keyword: this.searchInput,
      ...params,
      ...this.selectedFilter,
    }
    this.setLoading(true)
    const apiEndpoint = this.renderedUI === 0 || this.renderedUI === 2 ? this.api.getCatalogs(allParam) : this.api.getInventories(allParam)
    apiEndpoint.subscribe({
      next: (response: { records: Array<string>, meta: Pagination }) => {
        const records = response.records
        const meta = response.meta
        this.formattingData(records, loadMore, tabIndex)
        this.setPaginationMeta(meta, tabIndex)
        this.setLoading(false)
      },
      error: (error) => {
        console.log(error)
        this.setLoading(false)
      }
    })
  }

  setPaginationMeta(meta: Pagination, tabIndex: number) {
    this.allInventoryType[tabIndex].meta = meta
  }

  formattingData(records: Array<string>, loadMore: boolean, tabIndex: number) {
    if (this.renderedUI === 0 || this.renderedUI === 2) {
      this.allInventory = records.map((record: any) => ({
        ...record,
        image: '',
      }))
    } else if (this.renderedUI === 1) {
      this.allInventory = records.map((record: any) => ({
        catalogInfo: {
          type: '-',
          subtype: '-',
          brand: '-',
          model: '-',
        },
        ...record,
        image: '',
      }))

      // this.allInventoryType[0].count = records.reduce((total, item: any) => {
      //   if (item.type === 'Drone') {
      //     return total + item.drones.length;
      //   } else if (item.type === 'Payload') {
      //     return total + item.payload.length;
      //   }
      //   return total; // Return the unchanged total for other types
      // }, 0);


      // this.allInventoryType[1].count = records
      //   .filter((item: any) => item.type === 'Drone')
      //   .map((item: any) => item.drones.length)
      //   .reduce((total, count) => total + count, 0);

      // this.allInventoryType[2].count = records
      //   .filter((item: any) => item.type === 'Payload')
      //   .map((item: any) => item.payload.length)
      //   .reduce((total, count) => total + count, 0);

    }
    this.allInventoryType[tabIndex].data = !loadMore ? this.allInventory.flat() : [...this.allInventoryType[tabIndex].data, ...this.allInventory.flat()]
  }

  getFilterOptions() {
    const apiEndpoint = this.renderedUI === 0 || this.renderedUI === 2 ? this.api.getCatalogFilters() : this.api.getInventoryFilters()
    apiEndpoint.subscribe({
      next: (response) => {
        this.filterOptions = response
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  searchKeyup(searchInput: string) {
    this.searchInput = searchInput
    this.getAllInventory(this.selectedType.id)
  }

  filterHandler(data: FilterData) {
    this.selectedFilter = data
    this.activeFilter = data.activeFilter
    this.getAllInventory(this.selectedType.id)
  }

  navigateNextPage(tabIndex: number) {
    let param = {}
    if (this.allInventoryType[tabIndex].meta.nextCursor !== null) {
      this.setLoading(true)
      param = {
        nextCursor: this.allInventoryType[tabIndex].meta.nextCursor
      }
      setTimeout(() => {
        this.getAllInventory(tabIndex, param, true)
      }, 500)
    }
  }
  
  setLoading(state: boolean) {
    this.isLoading = state
  }

  scrollHandler(event: Event) {
    const element = event.target as HTMLElement
    if (element.scrollHeight - element.clientHeight <= element.scrollTop + 50) {
      if (!this.isLoading) {
        this.navigateNextPage(this.selectedType.id)
      }
    }
  }
}